// import React from 'react';
// import styled from "styled-components"
// import ImageCarousel from '../components/Events/ImageCarousel';
// import KeyEvents from '../components/Events/KeyEvents';
// import Upcoming from '../components/Events/UpcomingEvents';
// import Layout from "../components/layout"
// import SEO from '../components/seo'

// const StyledWrapper = styled.div`
//     .heading{
//         font-size:32px;
//         font-weight:800;
//         line-height:43px;
//     }

//     .sub-heading{
//         font-size:20px;
//         font-weight:800;
//         letter-spacing:1.87px;
//         line-height: 27px;
//     }

//     .sub-heading-date{
//         font-size:18px;
//         font-weight:600;
//         line-height: 24px;
//     }
// `

// const events = () => {
//     return (
//         <Layout>
//             <StyledWrapper className="container-fluid">
//                 {/* <SEO title={} /> */}
//                     <div className="row">
//                         <div className="col-lg-6 col-12 py-5 padding-left-8 padding-right-8">
//                             <h1 className="heading">Student Social Mobility Awards </h1>
//                             <div className="sub-heading">Award Ceremony</div>
//                             <div className="sub-heading-date">10th November 2020</div><br/>
//                             <div>Sean Coughlan reports for the BBC. Social mobility is so frozen that it would take five generations 
//                                 for a poorer family in the UK to reach the average income, an OECD report says.</div>
//                         </div>
//                     </div>
//                     <ImageCarousel /> 
//                     <KeyEvents />
//                     <Upcoming />
//             </StyledWrapper>
//         </Layout>
//     );
// }

// export default events;

import React, {useEffect, useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import {navigate} from "gatsby"

const StyledWrapper = styled.div`
  .align-center {
    text-align: center;
    padding-top: 10%;
    padding-bottom: 15%;
  }

  .timer, .redirect {
    color: #464fd0;
    font-weight: 700;
  }

`

const NotFoundPage = () => {
    const [timer, setTimer] = useState(5);

    useEffect(() => {
        window.setTimeout(() => {
            navigate("/")
        }, 5000);
    }, []);

    useEffect(() => {
        if (timer > 0) {
            setTimeout(() => setTimer(timer - 1), 1000);
        }
    }, timer);

    return (
        <Layout>
            <SEO title="404: Not found"/>
            <StyledWrapper>
                <div className="container my-5 pt-5">
                    <h1>We've recently upgraded our website</h1>
                    <p>This page has moved and we’re redirecting you to our homepage in <span
                        className="timer">{timer} seconds</span></p>
                    <p>If you're not redirected, <a className="redirect" href="/">please click here</a></p>
                </div>
            </StyledWrapper>
        </Layout>
    );
}

export default NotFoundPage
